.container-payments {
    max-width: 700px;
    margin: 40px 10px 10px 10px;
    padding: 20px 300px 20px 300px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
}

.container-payments-out {
    margin: 40px 10px 10px 10px;
    padding: 20px 60px 50px 60px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
}

.title1 {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: #a200e1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subtitle1 {
    text-align: center;
    font-size: 23px;
    font-weight: 1000;
    color: #9300ff;
    margin: 20px 0 10px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.form-group input[type="text"],
.form-group input[type="email"] {
    width: 100%;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 8px;
    border: 2px solid rgba(143, 131, 124, 0.43);
    border-radius: 8px;
    background-color: #340051;
    color: #fdfdfd;
    border-color: #54007e;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus {
    background-color: #2b0040;
    border-color: #580079;
}

.slider-group {
    text-align: center;
    width: 100%;
}

.slider-group label {
    margin-bottom: 10px;
    font-size: 16px;
    color: #ffffff;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #4200a1;
    outline: none;
    opacity: 0.9;
    transition: opacity .15s ease-in-out;
    border-radius: 3px;
}

.slider:hover {
    opacity: 1;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #6f00fd;
    cursor: pointer;
    border-radius: 40%;
}

.payment-methods {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.payment-option {
    padding: 10px;
    border: 2px solid rgb(88, 0, 129);
    border-radius: 8px;
    background-color: #350053;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #fff;
}

.payment-option span {
    display: block;
    font-weight: bold;
}

.payment-option input {
    display: none;
}

.payment-option:hover{
    background-color: #55007f;
    border-color: #8100b2;
    transform: scale(1.05);
}

.payment-option-selected {
    background-color: #55007f;
    border-color: #8100b2;
    transform: scale(1.05);
}


.product-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 1.5px solid #53007d;
    border-radius: 8px;
    background-color: #210131;
}


.product-text {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    color: #ffffff;
}

.button-payments {
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-payments:hover {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}

.button-payments:disabled, .button-payments.disabled {
    background-color: #2b0040;
    cursor: not-allowed;
    transform: none;
}

.disclaimer {
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
    color: #9200fd;
}

.paymetns-wrapper {
    color: #ffffff;
    padding: 20px;
    opacity: 100%;
}

.paymetns {
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 10px 28px;
    font-family: "Inter", sans-serif;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.paymetns-line {
    width: 100%;
    border: none;
    border-top: 6px solid #4100a0;
    margin: 5px 0;
    border-radius: 10px;
}


.same-size {
    flex: 1;
}

.custom-checkbox {
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #6600e7;
    border-radius: 4px;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #aa00ff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 8px;
    top: 2.5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox span {
    margin-left: -32px;
    margin-top: -2px;
}


.error {
    position: absolute;
    top: 100%;
    left: -5px;
    width: 100%;
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    z-index: 10;
    margin-top: 5px;
    pointer-events: none;
    opacity: 0.9;
}




