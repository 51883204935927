.navbar-wrapper {
  opacity: 100%;
  background-color: rgb(35, 0, 58);
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 20px 50px;
  font-family: "Inter", sans-serif;
  flex-wrap: wrap;
  position: relative;
}
.navbar .rotating-icon {
  width: 175px;
  height: 175px;
}


.rotating-icon {
  z-index: -1;
  animation: spin 120s linear infinite;
  position: absolute;
  right: -13px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}

.navbar-logo img {
  width: 150px;
  height: 150px;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(53, 0, 74);
  mix-blend-mode: soft-light;
  border: 2px solid #5b007b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  position: relative;
}

.navbar-links a {
  color: #a100df;
  text-decoration: none;
  margin: 0 20px;
  font-family: "Inter", sans-serif;
  font-weight: 1000;
  font-size: 18px;
  position: relative;
  padding: 15px;
  border-radius: 20px;
  z-index: 1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover {
  transform: scale(1.060);
}

.navbar-links a.active {
  color: #a100df;
}

.navbar-links a:hover:not(.active) {
  color: #852afd;
}

.active-indicator {
  position: absolute;
  bottom: 13px;
  left: -2px;
  height: 60%;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #5e007f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 0;
  display: none; /* Hide by default */
}

.active-indicator.visible {
  display: block; /* Show only when it should be visible */
}
