.shop-wrapper-out {
    margin: 40px 10px 10px 10px;
    padding: 20px 60px 50px 60px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
}

.shop-wrapper {
    color: #ffffff;
    padding: 20px;
    opacity: 100%;
    text-align: center;
    overflow-x: hidden;
}

/* Existing styles */
.shop {
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 10px 28px;
    font-family: "Inter", sans-serif;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.subtitle1 {
    text-align: center;
    font-size: 23px;
    font-weight: 1000;
    color: #9300ff;
    margin: 20px 0 10px;
}

.paymetns-line {
    width: 100%;
    border: none;
    border-top: 6px solid #4100a0;
    margin: 5px 0;
    border-radius: 10px;
}

.container-shop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 700px;
    margin: 40px 10px 10px 10px;
    padding: 20px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
}

.item-shop {
    flex: 1 0 30%; /* Adjusted for three items per row */
    margin: 10px;
    padding: 20px;
    background-color: #350053;
    color: #fff;
    border: 2px solid rgb(88, 0, 129);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.item-shop:hover {
    transform: scale(1.05);
}

.product-name {
    font-weight: bold;
    font-size: 20px;
    color: #a200e1;
}

.product-description {
    margin-top: 10px;
    font-size: 14px;
    color: #e0e0e0;
}

.sale-tag {
    margin-top: 10px;
    font-size: 14px;
    color: #ff0000;
    font-weight: bold;
}

.product-price {
    margin-top: 10px;
    font-size: 18px;
    color: #ffffff;
}

.button-payments {
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-payments:hover {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}
