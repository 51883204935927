.container-rules {
    max-width: 700px;
    margin: 40px 10px 10px 10px;
    padding: 20px 300px 20px 300px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
}

.container-rules-out {
    margin: 40px 10px 10px 10px;
    padding: 20px 60px 50px 60px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
}



.button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.rule-button {
    width: 100%;
    margin: 20px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: -30px;
    padding: 15px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.rule-button.active {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}
.rule-button:hover{
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}

.rules-wrapper {
    color: #ffffff;
    padding: 20px;
    opacity: 100%;
}

.rules {
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 10px 28px;
    font-family: "Inter", sans-serif;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.title4 {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: #a200e1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subtitle4 {
    text-align: center;
    font-size: 23px;
    font-weight: 1000;
    color: #9300ff;
    margin: 20px 0 10px;
}

.rule-content {
    margin-top: 60px;
}

.rule-description {
}


.rules-line {
    width: 100%;
    border: none;
    border-top: 6px solid #4400a3;
    margin: 5px 0;
    border-radius: 10px;
}




